<script>
import Tailor from "@/components/Tailor"

export default {
  components: {
    Tailor,
  },
  props: {
    /** 控制隐藏 */
    value: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    value() {
      // if (value) {
      //   this.init()
      // }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('input', false)
    },    
  },
}
</script>

<template>
  <a-modal
    title="图片剪裁"
    :visible="value"
    :bodyStyle="{paddingTop: 0}"
    :width="900"
    :footer="false"
    @cancel="handleCancel"
  >
    <Tailor v-if="value" v-bind="$attrs" v-on="$listeners"></Tailor>
  </a-modal>
</template>