/**
 * Base64字符串转二进制流
 * @param {String} dataurl Base64字符串(字符串包含Data URI scheme，例如：data:image/png;base64, )
 * @returns {Blob}
 */
export function dataURLtoBlob(dataurl) {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], {
		type: mime,
	});
}
/**
 * Base64字符串转二进制流
 * @param {String} base64 Base64字符串(字符串不包含Data URI scheme)
 * @param {String} type 文件类型(例如：image/png)
 * @returns {Blob}
 */
export function base64toBlob(base64, type) {
	// 将base64转为Unicode规则编码
	let bstr = atob(base64),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n); // 转换编码后才可以使用charCodeAt 找到Unicode编码
	}
	return new Blob([u8arr], {
		type,
	})
}
/**
 * Base64字符串转File文件
 * @param {String} dataurl Base64字符串(字符串包含Data URI scheme，例如：data:image/png;base64, )
 * @param {String} filename 文件名称
 * @returns {File}
 */
export function dataURLtoFile(dataurl, filename) {
	let arr = dataurl.split(',');
	let mime = arr[0].match(/:(.*?);/)[1];
	let bstr = atob(arr[1]);
	let n = bstr.length;
	let u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, {
		type: mime
	});
}
/**
 * Base64字符串转File文件
 * @param {String} base64 Base64字符串(字符串不包含Data URI scheme)
 * @param {String} filename 文件名称
 * @param {String} type 文件类型(例如：image/png)
 * @returns {File}
 */
export function base64toFile(base64, filename, type) {
	// 将base64转为Unicode规则编码
	let bstr = atob(base64);
	let n = bstr.length;
	let u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n); // 转换编码后才可以使用charCodeAt 找到Unicode编码
	}
	return new File([u8arr], filename, {
		type: type
	});
}